import React, { useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Loader } from 'rsuite';
import CloseIcon from '@mui/icons-material/Close';
import { Button, GradientButton, Input } from '..';
import {
  AddDomainDialog,
  CloseIconStyle,
  DomainLabel,
} from '../../pages/CompanyDetails/Tabs/Style/Tabs.style';
import { ButtonWrapper } from '../../pages/CompaniesList/Style/CompaniesList.style';

const LoadDomainModal = ({
  title,
  handleClose,
  open,
  isLoading,
  handleSubmit,
}: any) => {
  const [domains, setDomains] = useState('');
  const handleChangeDomains = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomains(event.target.value);
  };

  const handleCloseLoadInput = () => {
    setDomains('');
    handleClose();
  };
  return (
    <div>
      <AddDomainDialog onClose={handleCloseLoadInput} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>{title}</DialogTitle>
        <IconButton
          disabled={isLoading}
          onClick={handleCloseLoadInput}
          sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ '&.MuiDialogContent-root': { p: '16px 32px' } }}>
          <DomainLabel>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Domains
            </Typography>
            <div style={{ width: '100%' }}>
              <Input
                size="small"
                value={domains}
                onChange={handleChangeDomains}
                multiline
                rows={3}
                placeholder="domain1, domain2"
                sx={{ width: '100%' }}
              />
            </div>
          </DomainLabel>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleCloseLoadInput}
            color="secondary">
            Cancel
          </Button>
          <GradientButton
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              handleSubmit(domains);
            }}>
            {isLoading ? <Loader /> : 'Submit'}
          </GradientButton>
        </ButtonWrapper>
      </AddDomainDialog>
    </div>
  );
};

export default LoadDomainModal;
