import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Loader from '../Loader/Loader';
import {
  LayoutContainer,
  LoaderContainer,
  MainContainer,
} from './Style/Layout.style';

const DefaultLayout = ({ children }: any) => {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const getToken = async () => {
      const token: any = await getIdTokenClaims();
      setIsLoading(false);
      window.localStorage.setItem('userToken', token?.__raw);
      window.localStorage.setItem('authUserSub', JSON.stringify(user));
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [getIdTokenClaims, isAuthenticated]);

  return (
    <Box sx={LayoutContainer}>
      {isAuthenticated && !isLoading ? (
        <>
          <Header open={open} />
          <Sidebar
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <MainContainer>{children}</MainContainer>
        </>
      ) : (
        <LoaderContainer>
          <Loader size={30} />
        </LoaderContainer>
      )}
    </Box>
  );
};

export default DefaultLayout;
