import { CompanyReducerType } from '../../helpers/types';
import {
  GET_FALSE_POSITIVE_BEGIN,
  GET_FALSE_POSITIVE_FAILURE,
  GET_FALSE_POSITIVE_SUCCESS,
} from '../constant';

const initialState = {
  getFalsePositive: {
    loading: false,
    data: [],
    error: null,
  },
};
export const falsePositiveReducer = (
  state = initialState,
  action: CompanyReducerType,
) => {
  switch (action.type) {
    case GET_FALSE_POSITIVE_BEGIN:
      return {
        ...state,
        getFalsePositive: {
          ...state.getFalsePositive,
          loading: true,
        },
      };
    case GET_FALSE_POSITIVE_SUCCESS:
      return {
        ...state,
        getFalsePositive: {
          ...state.getFalsePositive,
          loading: false,
          data: action.payload,
        },
      };
    case GET_FALSE_POSITIVE_FAILURE:
      return {
        ...state,
        getFalsePositive: {
          ...state.getFalsePositive,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
