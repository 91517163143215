import { apiClient } from '../../services/apiClient';

export const getFalsePositiveApi = async (payload: { startDate: number }) => {
  const config = {
    method: 'GET',
    url: `domain/falsePositive?s=${payload.startDate}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const createFalsePositiveeDomainsApi = async (payload: string[]) => {
  const config = {
    method: 'POST',
    url: `domain/falsePositive`,
    data: payload,
  };
  const res = await apiClient(config);
  return res;
};
