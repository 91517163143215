import React from 'react';
import { Box } from '@mui/material';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { SingleDatePickerProps } from '../../helpers/types';

export const DatePickers = ({
  onChange,
  value,
  disableAfterToday,
}: SingleDatePickerProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DatePicker
        placeholder="YYYY-MM-DD"
        value={value}
        onChange={onChange}
        format="yyyy-MM-dd"
        shouldDisableDate={disableAfterToday}
      />
    </Box>
  );
};

export default DatePickers;
