import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dropdown, GradientButton, Input } from '../../../components';
import { ButtonWrapper } from '../../CompaniesList/Style/CompaniesList.style';
import {
  AddDomainDialog,
  CloseIconStyle,
  DomainLabel,
} from './Style/Tabs.style';
import {
  AddProtectedDomainModalType,
  CompaniesType,
} from '../../../helpers/types';
import { addProtectedDomainAction } from '../../../store/company/action';

const AddProtectedDomainModal = ({
  handleClose,
  open,
  domainCurrentPage,
  searchDomainName,
}: AddProtectedDomainModalType) => {
  const [protectedDomainName, setProtectedDomainName] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [isOwnedDomain, setIsOwnedDomain] = useState<boolean>(false);

  const { id } = useParams();
  const { getCompany } = useSelector((state: any) => state.company);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCompany?.data) {
      const company = getCompany.data.find(
        (company: any) => String(company.Id) === id,
      );
      if (company) {
        setSelectedCompany({ label: company.Name, id: company.Id });
      }
    }
  }, [getCompany, id]);

  const handleAddProtectedDomain = () => {
    const protectedDomains = protectedDomainName
      .split(',')
      .map(domain => domain.trim())
      .filter(domain => domain !== '');

    protectedDomains.map(domain => {
      dispatch(
        addProtectedDomainAction({
          name: domain,
          companyId: selectedCompany.id,
          ownDomain: isOwnedDomain,
          pn: domainCurrentPage,
          h: searchDomainName,
        }),
      );
    });
    handleClose();
    setProtectedDomainName('');
    setIsOwnedDomain(false);
  };

  const handleChangeProtectedDomain = (e: any) => {
    setProtectedDomainName(e.target.value);
  };

  const handleCompanyChange = (event: any, value: any) => {
    setSelectedCompany(value);
  };

  const handleCheckboxChange = (event: any) => {
    setIsOwnedDomain(event.target.checked);
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );

  return (
    <>
      <AddDomainDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Set Protection On Domain
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ '&.MuiDialogContent-root': { p: '16px 32px' } }}>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Company
            </Typography>
            <Dropdown
              items={companiesData}
              size="small"
              placeholder="company list"
              label="company list"
              onChange={handleCompanyChange}
              selectedValues={selectedCompany}
              multiple={false}
            />
          </Box>
          <DomainLabel>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Domains
            </Typography>
            <div style={{ width: '100%' }}>
              <Input
                size="small"
                value={protectedDomainName}
                onChange={handleChangeProtectedDomain}
                multiline
                rows={3}
                placeholder="domain1, domain2"
                sx={{ width: '100%' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isOwnedDomain}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Is owned domain ?"
              />
            </div>
          </DomainLabel>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton
            variant="contained"
            onClick={handleAddProtectedDomain}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </AddDomainDialog>
    </>
  );
};

export default AddProtectedDomainModal;
