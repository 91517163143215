import { call, put } from 'redux-saga/effects';
import { getFalsePositiveApi } from '.';
import {
  GET_FALSE_POSITIVE_FAILURE,
  GET_FALSE_POSITIVE_SUCCESS,
} from '../constant';
import { showToast } from '../toaster/action';
import { GetFalsePositiveActiontype } from '../../helpers/types';

export function* getFalsePostiveSaga(action: GetFalsePositiveActiontype): any {
  try {
    const response = yield call(getFalsePositiveApi, action.payload);
    yield put({
      type: GET_FALSE_POSITIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error?.message || 'Submission Active Defence failed, please try again.',
        'error',
      ),
    );
    yield put({
      type: GET_FALSE_POSITIVE_FAILURE,
      payload: error.response.data,
    });
  }
}
