import React, { useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Pagination,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableHeaderCell,
  TakedownTable,
} from '../ThreadsDetails/Style/SingleThreat.style';
import {
  findGSBStatus,
  formatedDate,
  getFormattedDescription,
  GSBHistoryHeader,
  LoaderWrapper,
  NoDataMessage,
} from '../../utils';
import { getStatusTakedown } from '../../store/takedown/action';
import { GSBHistory, GSBGetStatus } from '../../helpers/types';
import { getGsbHistoryAction } from '../../store/gsbHistory/action';
import { PaginationStyle } from './Style/GSBHistory.style';

const GSBStatusDetails = (threatDetail: any) => {
  const dispatch = useDispatch();

  const [gsbHistoryCurrentPage, setGsbHistoryCurrentPage] = useState<number>(1);
  const ThreadsDetails = threatDetail?.threatDetail;
  const { getStatustakeDown } = useSelector(
    (state: GSBGetStatus) => state.takedown,
  );
  const { gsbHistory } = useSelector((state: any) => state.gsbHistory);

  const totalItems = gsbHistory?.data?.total || 0;
  const pageSize = 10;
  const count = Math.ceil(totalItems / pageSize);

  useEffect(() => {
    dispatch(getStatusTakedown());
    fetchGSBHistory();
  }, [dispatch, gsbHistoryCurrentPage]);

  const fetchGSBHistory = () => {
    const params: any = {
      page: gsbHistoryCurrentPage,
      size: pageSize,
      threat_id: ThreadsDetails?.threat_id,
      threat_name: ThreadsDetails?.threat_name,
    };
    dispatch(getGsbHistoryAction(params));
  };

  const handleChangeGsbHistoryPage = (event: any, value: number) => {
    setGsbHistoryCurrentPage(value);
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        GSB Status History
      </Typography>
      <TakedownTable>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {GSBHistoryHeader.map((item, index) => (
                  <TableHeaderCell key={index} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            {gsbHistory?.loading ? (
              <LoaderWrapper size={25} />
            ) : gsbHistory?.data?.length === 0 ? (
              <NoDataMessage message="No GSB Status History Data" />
            ) : (
              <TableBody>
                {gsbHistory?.data?.map((item: GSBHistory, index: number) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        height: '50px',
                      }}>
                      <TableCell align="left">{item?.id}</TableCell>
                      <TableCell align="left">{item?.domain_id}</TableCell>
                      <TableCell align="left">{item?.url}</TableCell>
                      <TableCell align="left">{item?.platform}</TableCell>
                      <TableCell align="left">{item?.region_code}</TableCell>
                      <TableCell align="left">{item?.labels}</TableCell>
                      <TableCell align="left">
                        <Tooltip title={item?.comments || '-'}>
                          <span>{getFormattedDescription(item?.comments)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">{item?.abuse_type}</TableCell>
                      <TableCell align="left">
                        {formatedDate(item.created_at)}
                      </TableCell>
                      <TableCell align="left">{item?.operation_id}</TableCell>
                      <TableCell align="left">
                        {findGSBStatus(
                          item?.status_id,
                          getStatustakeDown?.data,
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {totalItems > pageSize && !gsbHistory?.loading && (
            <PaginationStyle>
              <Pagination
                count={count}
                page={gsbHistoryCurrentPage}
                onChange={handleChangeGsbHistoryPage}
                color="primary"
              />
            </PaginationStyle>
          )}
        </TableContainer>
      </TakedownTable>
    </>
  );
};

export default GSBStatusDetails;
