import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Grid, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  DatePickers,
  Dropdown,
  GradientButton,
} from '../../components';
import { getCompanyAction } from '../../store/company/action';
import { CompaniesType, ServicesType } from '../../helpers/types';
import { getStatusAction } from '../../store/threats/action';
import {
  defaultEndDate,
  defaultStartDate,
  disableAfterToday,
  getDateOrDefault,
  isDisabledStatus,
  SourcesData,
} from '../../utils';

const FilterCard = ({
  onChangeSatatuses,
  onChangeCompany,
  onChangeDate,
  handleApplyFilter,
  handleResetFilter,
  getPickerStartDate,
  getPickerEndDate,
  openSortBy,
  setOpenSortBy,
  handleUpdatedASCE,
  handleUpdatedDESC,
  handleDetectedASCE,
  handleDetectedDESC,
  onChangeService,
  getServicesData,
  onChangeSource,
  getSourcesData,
  onChangeExcludeCompany,
  isCustomerReviewPage,
}: any) => {
  const dispatch = useDispatch();
  const { getStatus, statusPersistValues, reviewStatusPersistValues } =
    useSelector((state: any) => state.threats);
  const { getCompany, companyPersistValues, getService, companysExclude } =
    useSelector((state: any) => state.company);

  useEffect(() => {
    dispatch(getCompanyAction());
    dispatch(getStatusAction());
  }, []);

  const handleCloseSortBy = () => {
    setOpenSortBy(null);
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );
  const statusesData = useMemo(() => {
    const statuses = getStatus?.data?.items?.map(
      (status: { name: string; id: string }) => ({
        label: status.name,
        id: status.id,
        disabled: isDisabledStatus(status.name, isCustomerReviewPage),
      }),
    );

    return statuses?.sort(
      (a: { disabled: boolean }, b: { disabled: boolean }) =>
        Number(a.disabled) - Number(b.disabled),
    );
  }, [getStatus?.data?.items, isCustomerReviewPage]);

  const servicesData = useCallback(
    getService?.data?.items?.map((service: ServicesType) => ({
      label: service?.name,
      id: service?.id,
    })),
    [getService?.data?.items],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <DatePickers
            size="small"
            onChange={onChangeDate}
            defaultStartDate={getDateOrDefault(
              getPickerStartDate,
              defaultStartDate,
            )}
            defaultEndDate={getDateOrDefault(getPickerEndDate, defaultEndDate)}
            disableAfterToday={disableAfterToday}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            items={companiesData}
            size="small"
            placeholder="Company"
            label="Company"
            onChange={onChangeCompany}
            selectedValues={companyPersistValues}
            multiple={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            items={statusesData || []}
            size="small"
            placeholder="Status"
            label="Status"
            onChange={onChangeSatatuses}
            selectedValues={
              isCustomerReviewPage
                ? reviewStatusPersistValues
                : statusPersistValues
            }
            multiple={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            items={servicesData || []}
            size="small"
            placeholder="Service"
            label="Service"
            onChange={onChangeService}
            selectedValues={getServicesData}
            multiple={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            items={SourcesData || []}
            size="small"
            placeholder="Sources"
            label="Sources"
            onChange={onChangeSource}
            selectedValues={getSourcesData}
            multiple={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            items={companiesData}
            size="small"
            placeholder="Exclude Company"
            label="Exclude Company"
            onChange={onChangeExcludeCompany}
            selectedValues={companysExclude}
            multiple={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <GradientButton onClick={handleApplyFilter}>Apply</GradientButton>
            <Button onClick={handleResetFilter} variant="outlined">
              Reset
            </Button>
            <Button
              style={{ color: 'black', gap: '4px' }}
              onClick={event => setOpenSortBy(event.currentTarget)}
              variant="text">
              <FilterListIcon />
              Sort By
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={openSortBy}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(openSortBy)}
              onClose={handleCloseSortBy}>
              <MenuItem onClick={handleUpdatedASCE}>Updated_at ASCE</MenuItem>
              <MenuItem onClick={handleUpdatedDESC}>Updated_at DESC</MenuItem>
              <MenuItem onClick={handleDetectedASCE}>Detected_at ASCE</MenuItem>
              <MenuItem onClick={handleDetectedDESC}>Detected_at DESC</MenuItem>
              <MenuItem onClick={handleUpdatedDESC}>Unsort</MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterCard;
