import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Skeleton,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ConfirmationModal, SelectOption } from '..';
import { formatedDate, threatsStatus } from '../../utils';
import { ThreatDetails } from '../ThreatCard/ThreatCard.style';
import { setAnalyticObject } from '../../store/analytic/action';
import { PAGE_NAME } from '../../utils/analytic';
import { changeStatusLabelAction } from '../../store/threats/action';
import useCustomThreatHook from '../../utils/threatHooks';

const ThreatTable = ({
  threats,
  handleChangeSelectedThreats,
  isThreatSelected,
}: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedThreat, setSelectedThreat] = useState<any>(null);

  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);

  const { threatData, fetchNetworkRecords } = useCustomThreatHook();

  useEffect(() => {
    if (threats && threats.items && threats.items.length) {
      threats.items.forEach((item: any) => {
        fetchNetworkRecords('whois', item);
        fetchNetworkRecords('certificate', item);
      });
    }
  }, [threats]);

  const findCompanyNameById = (id: number) => {
    const company = getCompany?.data?.find((company: any) => company.Id === id);
    return company ? company.Name : 'Unknown';
  };

  const handleChangeThreatStatus = (threat: any, newStatus: string) => {
    setOpenModal(true);
    setSelectedThreat({ ...threat, newStatus });
  };
  const updateSingleStatus = async () => {
    const {
      protected_asset_name,
      protected_asset_id,
      threat_name,
      threat_id,
      company_id,
      identification_status,
      newStatus,
    } = selectedThreat;
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: false,
    };
    dispatch(setAnalyticObject(analytic));
    await dispatch(
      changeStatusLabelAction({
        threat_name: threat_name,
        status: newStatus,
        user_id: user?.sub,
        company_id: company_id,
        domain_id: protected_asset_id,
        domain: protected_asset_name,
        threat_id: threat_id,
        process: 'w',
        priority: 1,
        currentStatus: identification_status.value,
      }),
    );
    setOpenModal(false);
  };

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #e2e2e2' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Threat Name</TableCell>
            <TableCell>Target Domain</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>CTI Score</TableCell>
            <TableCell>SSL</TableCell>
            <TableCell>Registrar</TableCell>
            <TableCell>Registration</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell>Detection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {threats?.items?.map((items: any, index: number) => {
            const { threat_id: threatId } = items;
            const domainInfo = threatData[threatId]?.whois?.domain_information;
            const sslData = threatData[threatId]?.certificate;
            const whoisDataLoading = threatData[threatId]?.whoisLoading;
            const sslDataLoading = threatData[threatId]?.certificateLoading;

            return (
              <TableRow key={`${index}${items.threat_id}`}>
                <TableCell>
                  <Checkbox
                    checked={isThreatSelected({
                      threatId: items.threat_id,
                      companyId: items.company_id,
                      domainId: items.protected_asset_id,
                    })}
                    onChange={() =>
                      handleChangeSelectedThreats({
                        domain: items.protected_asset_name,
                        threat: items.threat_name,
                        threatId: items.threat_id,
                        companyId: items.company_id,
                        domainId: items.protected_asset_id,
                        status: items.identification_status.value,
                      })
                    }
                    sx={{ paddingLeft: '0' }}
                  />
                  <Link
                    to={`/threats/${items?.threat_name}/${items?.protected_asset_name}/${items?.company_id}?protected_id=${items?.protected_asset_id}&threat_id=${items?.threat_id}`}
                    target="_blank"
                    style={ThreatDetails}>
                    {items?.threat_name?.length > 20
                      ? items.threat_name.substring(0, 20) + '...'
                      : items.threat_name}
                  </Link>
                </TableCell>
                <TableCell>{items?.protected_asset_name}</TableCell>
                <TableCell>{findCompanyNameById(items?.company_id)}</TableCell>
                <TableCell>
                  <SelectOption
                    size="small"
                    width="120px"
                    value={items.identification_status?.value}
                    options={threatsStatus.map(status => ({
                      label: status,
                      value: status,
                    }))}
                    onChange={(e: any) => {
                      const newStatus = e.target.value;
                      handleChangeThreatStatus(items, newStatus);
                    }}
                  />
                </TableCell>
                <TableCell>{items?.score?.value || '-'}</TableCell>
                <TableCell>
                  {sslDataLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={'100%'}
                    />
                  ) : (
                    sslData?.issuer || '-'
                  )}
                </TableCell>
                <TableCell>
                  {whoisDataLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={'100%'}
                    />
                  ) : (
                    domainInfo?.registrar || '-'
                  )}
                </TableCell>

                <TableCell>
                  {whoisDataLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={'100%'}
                    />
                  ) : domainInfo?.registration_date &&
                    domainInfo?.registration_date !== 'undefined' ? (
                    formatedDate(domainInfo?.registration_date)
                  ) : (
                    'NA'
                  )}
                </TableCell>

                <TableCell>
                  {whoisDataLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={'100%'}
                    />
                  ) : domainInfo?.expiration_date &&
                    domainInfo?.expiration_date !== 'undefined' ? (
                    formatedDate(domainInfo?.expiration_date)
                  ) : (
                    'NA'
                  )}
                </TableCell>
                <TableCell>
                  {' '}
                  {items?.detection_date &&
                  items?.detection_date !== 'undefined'
                    ? formatedDate(items?.detection_date)
                    : 'NA'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdate={() => updateSingleStatus()}
      />
    </TableContainer>
  );
};

export default ThreatTable;
