import {
  GetGsbHistoryAction,
  GetGsbHistoryFailureAction,
  GetGsbHistorySuccessAction,
  GsbHistoryItem,
  GSBHistoryPayload,
} from '../../helpers/types';
import {
  GET_GSB_HISTORY_BEGIN,
  GET_GSB_HISTORY_FAILURE,
  GET_GSB_HISTORY_SUCCESS,
} from '../constant';

export const getGsbHistoryAction = (
  payload: GSBHistoryPayload,
): GetGsbHistoryAction => ({
  type: GET_GSB_HISTORY_BEGIN,
  payload,
});

export const getGsbHistorySuccess = (
  payload: GsbHistoryItem,
): GetGsbHistorySuccessAction => ({
  type: GET_GSB_HISTORY_SUCCESS,
  payload,
});

export const getGsbHistoryFailure = (
  payload: string,
): GetGsbHistoryFailureAction => ({
  type: GET_GSB_HISTORY_FAILURE,
  payload,
});
