import { GSBHistoryPayload } from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const fetchGSBHistoryApi = async (payload: GSBHistoryPayload) => {
  let queryString = `page=${payload.page}&size=${payload.size}`;

  if (payload.start_date) queryString += `&start_date=${payload.start_date}`;
  if (payload.end_date) queryString += `&end_date=${payload.end_date}`;
  if (payload.url) queryString += `&url=${payload.url}`;
  if (payload.threat_id) queryString += `&threat_id=${payload.threat_id}`;
  if (payload.threat_name) queryString += `&threat_name=${payload.threat_name}`;

  const config = {
    method: 'GET',
    url: `v2/assets/gsb_submission_history?${queryString}`,
  };

  return await apiClient(config);
};
