import React, { useCallback, useEffect } from 'react';
import { Box, Typography, Paper, TableContainer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SyncIcon from '@mui/icons-material/Sync';
import {
  getDnsRecordsActions,
  getSSLRecordsActions,
  getSubDomainsAction,
  getWhoisRecordsActions,
} from '../../store/singleThreat/action';
import DNSRecordsTab from './ThreadsMoreDetails/DNSRecordsTab';
import SSLCertificateTab from './ThreadsMoreDetails/SSLCertificateTab';
import WhoisTab from './ThreadsMoreDetails/WhoisTab';
import SubDomainsTab from './ThreadsMoreDetails/SubDomainsTab ';
import { ThreatTypes } from '../../helpers/types';
import {
  NoDataWrapper,
  SubTitleWrapper,
  SyncIconStyle,
  TabsLoaderWrapper,
  WhoisLoaderWrapper,
} from './Style/SingleThreat.style';
import { Loader } from '../../components';

const threatDetailLoader = () => {
  return (
    <TabsLoaderWrapper>
      <Loader size={25} />
    </TabsLoaderWrapper>
  );
};

const whoisLoader = () => {
  return (
    <WhoisLoaderWrapper>
      <Loader size={25} />
    </WhoisLoaderWrapper>
  );
};

const noRecordsFound = () => {
  return <NoDataWrapper>No records found</NoDataWrapper>;
};

const DomainInformation = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const dispatch = useDispatch();
  const { subDomains, whoisRecords, sslCertificateRecords, dnsRecords } =
    useSelector((state: any) => state.singleThreat);

  const dispatchWhoisActions = useCallback(() => {
    if (singleThreat?.threat_id) {
      dispatch(
        getWhoisRecordsActions({
          asset_id: singleThreat?.threat_id,
          type: 'whois',
        }),
      );
    }
  }, [singleThreat?.threat_id]);

  const dispatchSSLRecordsActions = useCallback(() => {
    if (singleThreat?.threat_id) {
      dispatch(
        getSSLRecordsActions({
          asset_id: singleThreat?.threat_id,
          type: 'certificate',
        }),
      );
    }
  }, [singleThreat?.threat_id]);

  const dispatchDnsRecordsActions = useCallback(() => {
    if (singleThreat?.threat_id) {
      dispatch(
        getDnsRecordsActions({
          asset_id: singleThreat?.threat_id,
          type: 'records',
        }),
      );
    }
  }, [singleThreat?.threat_id]);

  useEffect(() => {
    if (singleThreat?.protected_asset_id) {
      dispatch(getSubDomainsAction(singleThreat?.protected_asset_id));
      dispatchWhoisActions();
      dispatchSSLRecordsActions();
      dispatchDnsRecordsActions();
    }
  }, [singleThreat?.protected_asset_id]);

  const handleRefreshDomain = (type: 'whois' | 'certificate' | 'records') => {
    if (type === 'whois') {
      dispatchWhoisActions();
    }
    if (type === 'certificate') {
      dispatchSSLRecordsActions();
    }
    if (type === 'records') {
      dispatchDnsRecordsActions();
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Domain Information
      </Typography>
      <Box sx={{ ml: 2, mt: 1 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            SSL Certificate
          </Typography>
          <SyncIcon
            sx={SyncIconStyle}
            onClick={() => handleRefreshDomain('certificate')}
          />
        </SubTitleWrapper>
        {sslCertificateRecords?.loading ? (
          threatDetailLoader()
        ) : sslCertificateRecords?.data?.data?.length === 0 ? (
          noRecordsFound()
        ) : (
          <SSLCertificateTab
            sslCertificate={sslCertificateRecords?.data?.items?.[0]}
          />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 1.5 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            Whois
          </Typography>
          <SyncIcon
            sx={SyncIconStyle}
            onClick={() => handleRefreshDomain('whois')}
          />
        </SubTitleWrapper>
        {whoisRecords?.loading ? (
          whoisLoader()
        ) : (
          <WhoisTab whois={whoisRecords?.data?.items?.[0]} />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 2 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            Records
          </Typography>
          <SyncIcon
            sx={SyncIconStyle}
            onClick={() => handleRefreshDomain('records')}
          />
        </SubTitleWrapper>
        {dnsRecords?.loading ? (
          threatDetailLoader()
        ) : dnsRecords?.data?.length === 0 ? (
          noRecordsFound()
        ) : (
          <DNSRecordsTab dnsRecord={dnsRecords?.data?.items?.[0]?.data} />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'black' }}>
          Subdomains
        </Typography>
        <TableContainer
          sx={{ mt: 0.5, p: 2, width: 'auto', height: '250px' }}
          component={Paper}>
          {subDomains?.loading ? (
            threatDetailLoader()
          ) : subDomains?.data?.length === 0 ? (
            noRecordsFound()
          ) : (
            <SubDomainsTab subDomains={subDomains?.data} />
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default DomainInformation;
