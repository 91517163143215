import { CompanyReducerType } from '../../helpers/types';
import {
  GET_ACTIVE_DEFENCE_BEGIN,
  GET_ACTIVE_DEFENCE_FAILURE,
  GET_ACTIVE_DEFENCE_SUCCESS,
} from '../constant';

const initialState = {
  activeDefence: {
    loading: false,
    data: [],
    error: null,
  },
};
export const activeDefenceReducer = (
  state = initialState,
  action: CompanyReducerType,
) => {
  switch (action.type) {
    case GET_ACTIVE_DEFENCE_BEGIN:
      return {
        ...state,
        activeDefence: {
          ...state.activeDefence,
          loading: true,
        },
      };
    case GET_ACTIVE_DEFENCE_SUCCESS:
      return {
        ...state,
        activeDefence: {
          ...state.activeDefence,
          loading: false,
          data: action.payload,
        },
      };
    case GET_ACTIVE_DEFENCE_FAILURE:
      return {
        ...state,
        activeDefence: {
          ...state.activeDefence,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
