import React, { useState } from 'react';
import { Box, Typography, Modal, TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { CreateThreatModelType } from '../../helpers/types';
import { createThreatAction } from '../../store/threats/action';
import { Button, GradientButton, Input } from '../../components';

const AddModelWrapper = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonWrapper = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  justifyContent: 'center',
  mt: 3,
};

const CreateThreatModel = ({ open, handleClose }: CreateThreatModelType) => {
  const [selectedDomain, setSelectedDomain] = useState<any>(null);
  const [newThreatName, setNewThreatName] = useState<string>('');

  const dispatch = useDispatch();
  const { user } = useAuth0();
  const protectedDomains = useSelector(
    (state: any) => state.threats?.domainList?.data?.Items,
  );
  const { datePickerPersistValues } = useSelector(
    (state: any) => state.company,
  );

  const handleCreateThreat = () => {
    dispatch(
      createThreatAction({
        domain_id: selectedDomain?.Domain.Id,
        company_id: selectedDomain?.Company.Id,
        threat_name: newThreatName,
        status: 'confirmed',
        user_id: user?.sub,
        process: 'w',
        priority: 1,
        from: datePickerPersistValues.startDate,
        until: datePickerPersistValues.endDate,
      }),
    );
    handleClose();
    setSelectedDomain(null);
    setNewThreatName('');
  };

  const onChangeDomainItems = (event: React.SyntheticEvent, value: any) => {
    const domainObject = protectedDomains.find(
      (item: any) => item.Domain.Name === value,
    );
    setSelectedDomain(domainObject);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={AddModelWrapper}>
          <Typography variant="h6" component="h2">
            Add Threat Details
          </Typography>
          <Typography sx={{ mt: 2, mb: 1 }}>Protected Domain :</Typography>
          <Autocomplete
            size="small"
            value={selectedDomain?.Domain?.Name || ''}
            onChange={onChangeDomainItems}
            options={
              protectedDomains?.map((item: any) => item?.Domain?.Name) || []
            }
            renderInput={params => (
              <TextField {...params} placeholder="domain name" />
            )}
          />
          <Typography sx={{ mt: 2, mb: 1 }}>Threat Name :</Typography>
          <Input
            size="small"
            placeholder="threat name"
            value={newThreatName}
            sx={{ width: '100%' }}
            onChange={e => setNewThreatName(e.target.value)}
          />
          <Box sx={buttonWrapper}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              label="Cancel"
            />
            <GradientButton
              onClick={handleCreateThreat}
              label="Add"
              type="submit"
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateThreatModel;
