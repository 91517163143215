import { apiClient } from '../../services/apiClient';

export const getActiveDefenceApi = async (payload: { startDate: number }) => {
  const config = {
    method: 'GET',
    url: `domain/activeDefense?s=${payload.startDate}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const createActiveDefenceDomainsApi = async (payload: string[]) => {
  const config = {
    method: 'POST',
    url: `domain/activeDefense`,
    data: payload,
  };
  const res = await apiClient(config);
  return res;
};
