import React from 'react';
import { Typography, Modal, Box } from '@mui/material';
import { Button, GradientButton } from '..';
import { ConfirmationModalProps } from '../../helpers/types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none !important',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const buttonWrapper = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  justifyContent: 'center',
  mt: 2,
};

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  subTitle,
  confirmLabel,
  handleUpdate,
}: ConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} style={{ width: 400 }}>
        <Typography variant="subtitle1" fontWeight="bold">
          {title}
        </Typography>
        <Typography sx={{ mt: 1 }}>{subTitle}</Typography>
        <Box sx={buttonWrapper}>
          <Button onClick={handleClose} variant="outlined" label="Cancel" />
          <GradientButton
            variant="contained"
            onClick={() => {
              handleUpdate();
            }}>
            {confirmLabel}
          </GradientButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
