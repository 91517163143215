import {
  GET_GSB_HISTORY_BEGIN,
  GET_GSB_HISTORY_FAILURE,
  GET_GSB_HISTORY_SUCCESS,
} from '../constant';

const initialState = {
  gsbHistory: {
    loading: false,
    data: [],
    error: null,
  },
};

export const gsbHistoryReducer = (
  state = initialState,
  action: { type: string; payload: string },
) => {
  switch (action.type) {
    case GET_GSB_HISTORY_BEGIN:
      return {
        ...state,
        gsbHistory: {
          ...state.gsbHistory,
          loading: true,
        },
      };
    case GET_GSB_HISTORY_SUCCESS:
      return {
        ...state,
        gsbHistory: {
          ...state.gsbHistory,
          loading: false,
          data: action.payload,
        },
      };
    case GET_GSB_HISTORY_FAILURE:
      return {
        ...state,
        subDomains: {
          ...state.gsbHistory,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
