import React, { useEffect, useState } from 'react';
import { DialogTitle, Box, Modal, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Loader } from '../../components';
import {
  NotesHeader,
  NotesWrapper,
  ViewModalStyle,
  ViewNotesWrapper,
} from './Style/ViewThreatNotesModal.style';
import {
  createNotesAction,
  getNotesIdAction,
} from '../../store/noteComments/action';
import NoteList from '../../components/NoteList/NoteList';

const AddCommonNotesModal = ({ open, handleClose, selectedThreats }: any) => {
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);

  const dispatch = useDispatch();
  const { getNotesValues, createNotes, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );

  useEffect(() => {
    if (open) {
      selectedThreats.map((item: { domainId: number; threatId: number }) => {
        dispatch(
          getNotesIdAction({
            domain_id: item.domainId,
            threat_id: item.threatId,
            page: 1,
            size: 50,
          }),
        );
      });
    }
  }, [open]);

  useEffect(() => {
    if (getNoteId?.data?.items) {
      const noteIds = getNoteId.data.items.map(
        (item: { note_id: number }) => item.note_id,
      );
      setSelectedNoteIds(noteIds);
    }
  }, [getNoteId]);

  const handleApplyNotes = () => {
    const items = selectedThreats
      .map((threat: { domainId: number; threatId: number }) =>
        selectedNoteIds.map(noteId => ({
          domain_id: threat.domainId,
          threat_id: threat.threatId,
          note_id: noteId,
        })),
      )
      .flat();
    handleClose();
    dispatch(
      createNotesAction({
        items,
      }),
    );
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={ViewModalStyle} style={{ width: '60%' }}>
          <NotesHeader>
            <DialogTitle sx={{ m: 0, p: 0 }}>Notes</DialogTitle>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<CheckCircleIcon />}
                sx={{
                  background: '#0fd6c2',
                  mr: 1,
                  minWidth: 90,
                  padding: '6px 13px',
                }}
                disabled={selectedNoteIds.length === 0 || createNotes.loading}
                onClick={handleApplyNotes}>
                {createNotes.loading ? <Loader size={18} /> : 'Apply'}
              </Button>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                label="Cancel"
              />
            </Box>
          </NotesHeader>
          <ViewNotesWrapper>
            <Grid container spacing={1}>
              <Typography sx={{ mb: 1, mt: 1 }}>Threat Notes List:</Typography>
              <NotesWrapper>
                {getNotesValues.loading ? (
                  <Box sx={{ m: 12 }}>
                    <Loader size={25} />
                  </Box>
                ) : (
                  <Grid container spacing={{ xs: 1.5 }}>
                    <NoteList
                      handleNotesChange={handleNotesChange}
                      selectedNoteIds={selectedNoteIds}
                      handleApplyNotes={handleApplyNotes}
                    />
                  </Grid>
                )}
              </NotesWrapper>
            </Grid>
          </ViewNotesWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default AddCommonNotesModal;
