import {
  HistoryStatuesType,
  NetWorkActionType,
  SubmitData,
  ThreatData,
} from '../../helpers/types';
import {
  GET_DNS_RECORDS_BEGIN,
  GET_HISTORY_STATUSES_BEGIN,
  GET_LABLE_BEGIN,
  GET_NETWORK_BEGIN,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN,
  GET_PLATFORM_BEGIN,
  GET_REGION_CODE_BEGIN,
  GET_SSL_RECORDS_BEGIN,
  GET_SUBDOMAINS_BEGIN,
  GET_WHOIS_RECORDS_BEGIN,
  SUBMIT_TO_GSB_BEGIN,
} from '../constant';

export const getHistoryStatusesAction = (payload: HistoryStatuesType) => ({
  type: GET_HISTORY_STATUSES_BEGIN,
  payload,
});

export const getSubDomainsAction = (payload: number | null) => ({
  type: GET_SUBDOMAINS_BEGIN,
  payload,
});

export const getNetworkAction = (payload: number | null) => ({
  type: GET_NETWORK_BEGIN,
  payload,
});

export const getWhoisRecordsActions = (payload: NetWorkActionType) => ({
  type: GET_WHOIS_RECORDS_BEGIN,
  payload,
});

export const getSSLRecordsActions = (payload: NetWorkActionType) => ({
  type: GET_SSL_RECORDS_BEGIN,
  payload,
});

export const getDnsRecordsActions = (payload: NetWorkActionType) => ({
  type: GET_DNS_RECORDS_BEGIN,
  payload,
});

export const fetchRegionCodes = () => ({
  type: GET_REGION_CODE_BEGIN,
});

export const fetchPlatforms = () => ({
  type: GET_PLATFORM_BEGIN,
});

export const fetchNetworkThreats = () => ({
  type: GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN,
});

export const fetchLabels = () => ({
  type: GET_LABLE_BEGIN,
});

export const submitToGsbAction = (payload: SubmitData) => ({
  type: SUBMIT_TO_GSB_BEGIN,
  payload,
});
