import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePickers, GradientButton, Input } from '../../components';
import CompaniesTable from './CompaniesTable';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import { getCompanyListAction } from '../../store/company/action';
import AddCompanyModal from './AddCompanyModal';
import {
  CompanyInputStyle,
  FilterWrapper,
  SearchButton,
} from './Style/CompaniesList.style';

const CompaniesList = () => {
  const [companyCurrentPage, setCompanyCurrentPage] = useState<number>(1);
  const [searchCompanyName, setSearchCompanyName] = useState<string>('');
  const [startDateFilterValues, setStartDateFilterValues] =
    useState<string>('');
  const [endDateFilterValues, setEndDateFilterValues] = useState<string>('');
  const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);

  const { getCompanyList } = useSelector((state: any) => state.company);
  const dispatch = useDispatch();
  const count = Math.ceil(getCompanyList?.data?.Total / 10);

  const fetchCompanyList = () => {
    dispatch(
      getCompanyListAction({
        d: 'y',
        u: 'n',
        s: 'y',
        h: searchCompanyName,
        ipp: 10,
        pn: companyCurrentPage,
        r: 'desc',
        m: 'created',
      }),
    );
  };

  useEffect(() => {
    if (companyCurrentPage) {
      fetchCompanyList();
    }
  }, [companyCurrentPage]);

  const handleChangeCompanyPage = (event: any, value: number) => {
    setCompanyCurrentPage(value);
  };

  const handleChangeCompanyName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchCompanyName(e.target.value);
  };

  const handleCompanySearch = () => {
    setCompanyCurrentPage(1);
    fetchCompanyList();
    setSearchCompanyName('');
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const formattedStartDate = dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = dayjs(value[1]).format('YYYY-MM-DD HH:mm:ss');
      setStartDateFilterValues(formattedStartDate);
      setEndDateFilterValues(formattedEndDate);
    } else {
      setStartDateFilterValues('');
      setEndDateFilterValues('');
    }
  };

  const handleAddThreatModal = () => {
    setOpenAddCompany(true);
  };

  return (
    <>
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
          Brands
        </Typography>
        <FilterWrapper>
          <Box sx={{ display: 'flex' }}>
            <Input
              size="small"
              placeholder="company name"
              onChange={handleChangeCompanyName}
              value={searchCompanyName}
              sx={CompanyInputStyle}
            />
            <Box sx={{ width: '270px' }}>
              <DatePickers size="small" onChange={onChangeDate} />
            </Box>
            <SearchButton variant="contained" onClick={handleCompanySearch}>
              <SearchIcon />
              <span style={{ paddingLeft: '2px' }}>Search</span>
            </SearchButton>
          </Box>
          <Box sx={{ gap: '12px', display: 'flex' }}>
            <GradientButton variant="contained" onClick={handleAddThreatModal}>
              + Add Entity
            </GradientButton>
          </Box>
        </FilterWrapper>
        <CompaniesTable
          searchCompanyName={searchCompanyName}
          companyCurrentPage={companyCurrentPage}
        />
        {getCompanyList?.data?.Total > 10 && !getCompanyList?.loading && (
          <PaginationStyle>
            <Pagination
              count={count}
              page={companyCurrentPage}
              onChange={handleChangeCompanyPage}
              color="primary"
            />
          </PaginationStyle>
        )}
      </Box>
      <AddCompanyModal
        open={openAddCompany}
        handleClose={() => setOpenAddCompany(false)}
      />
    </>
  );
};

export default CompaniesList;
