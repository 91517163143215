import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import Login from './pages/Login/Login';
import SingleThreatDetail from './pages/ThreadsDetails/SingleThreatDetail';
import TakedownList from './pages/TakedownList/TakedownList';
import CompaniesList from './pages/CompaniesList/CompaniesList';
import CompanyDetails from './pages/CompanyDetails/CompanyDetails';
import ActiveDefense from './pages/ActiveDefense/ActiveDefense';
import FalsePositive from './pages/FalsePositive/FalsePositive';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import EnrichTakedown from './pages/TakedownList/EnrichTakedown';
import DefaultLayout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import TakedownStatusHistory from './pages/TakedownList/TakedownStatusHistory';
import useApiInterceptor from './helpers/useApiInterceptor';
import GSBHistory from './pages/GSBHistory/GSBHistory';

function App() {
  useApiInterceptor();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoute element={<Login />} />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <Home />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/threats/customer-review-requests"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <Home />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="threats/:threatName/:domainName/:companyId"
          element={<SingleThreatDetail />}
        />
        <Route
          path="/takedown"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <Outlet />
                </DefaultLayout>
              }
            />
          }>
          <Route index element={<TakedownList />} />
          <Route
            path="history/:takedown_id"
            element={<TakedownStatusHistory />}
          />
        </Route>
        <Route
          path="/enrich-takedown"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <EnrichTakedown />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/companies"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <CompaniesList />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/companies/:id/view"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <CompanyDetails />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/network-disruption"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <ActiveDefense />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/false-positive"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <FalsePositive />
                </DefaultLayout>
              }
            />
          }
        />
        <Route
          path="/gsb-history"
          element={
            <PrivateRoute
              element={
                <DefaultLayout>
                  <GSBHistory />
                </DefaultLayout>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
