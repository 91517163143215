import {
  CompleteTakedownType,
  CreateTakedownType,
  EnrichTakedownType,
  GetTakedonwDetailType,
  getTakeDownListType,
} from '../../helpers/types';
import {
  COMPLETE_TAKEDOWN_BEGIN,
  CREATE_TAKEDOWN_BEGIN,
  GET_HUBSPORTID_BEGIN,
  GET_STATUS_TAKEDOWN_BEGIN,
  GET_TAKEDOWN_TYPES_BEGIN,
  GET_TAKEDOWN_THREAT_DETAIL_BEGIN,
  GET_TAKEDOWNLIST_BEGIN,
  TAKEDOWN_STATUS_HISTRORY_BEGIN,
  UPDATE_ENRICH_TAKEDOWN_BEGIN,
} from '../constant';

export const getTakeDownListAction = (payload: getTakeDownListType) => ({
  type: GET_TAKEDOWNLIST_BEGIN,
  payload,
});

export const createTakedownAction = (payload: CreateTakedownType) => ({
  type: CREATE_TAKEDOWN_BEGIN,
  payload,
});

export const completeTakedownAction = (payload: CompleteTakedownType) => ({
  type: COMPLETE_TAKEDOWN_BEGIN,
  payload,
});

export const getHubSportIdAction = (payload: string | null) => ({
  type: GET_HUBSPORTID_BEGIN,
  payload,
});

export const getTakedownDetail = (payload: GetTakedonwDetailType) => ({
  type: GET_TAKEDOWN_THREAT_DETAIL_BEGIN,
  payload,
});

export const getStatusTakedown = (payload?: {takedown_type: string}) => ({
  type: GET_STATUS_TAKEDOWN_BEGIN,
  payload,
});

export const takedownStatusHistrory = (payload: { takedown_id: string }) => ({
  type: TAKEDOWN_STATUS_HISTRORY_BEGIN,
  payload,
});

export const updateEnrichTakedownAction = (payload: EnrichTakedownType) => ({
  type: UPDATE_ENRICH_TAKEDOWN_BEGIN,
  payload,
});

export const fetchTakedownTypes = () => ({
  type: GET_TAKEDOWN_TYPES_BEGIN,
});